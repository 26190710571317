import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../index.scss';
import './guides.scss';
import '../../generic-page.scss';
import { Card, Col, Row } from 'react-bootstrap';
import { Reverse1999Character } from '../../../modules/reverse/common/components/rev-character';
import { Reverse1999Psychube } from '../../../modules/reverse/common/components/rev-psychube';

const RevShouldIsolde: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page reverse r99-guide'}
      game="reverse"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/re1999/">Reverse: 1999</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/re1999/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Should you pull Isolde?</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/reverse/categories/category_marcus.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Should you pull Marcus?</h1>
          <h2>
            Not sure if pulling Marcus is the right move? No worries! Check our
            review and guide for her!
          </h2>
          <p>
            Last updated: <strong>25/07/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Should You Pull? (TLDR)" />
        <div className="employees-container-card-mode-rev">
          <Reverse1999Character
            slug="marcus"
            mode="icon"
            showTags
            showIcon
            enablePopover
          />
        </div>
        <p>
          While Marcus is undoubtedly the strongest Plant carry on CN at the
          moment, when deciding whether to pull her or not, you must take into
          consideration her team building options. Her Insight 3 requires at
          least two or more allies to be of the Plant/Intellect/Spirit Afflatus
          in order to trigger her passive, which will limit her team building
          options, especially for 3-People content.
        </p>
        <p>
          However, it’s important to take note that every new patch after 1.7
          has at least one character that greatly benefits Marcus. If you either
          already have a good cast of the aforementioned Afflatuses, or if you
          plan to pull for two or more of the upcoming characters listed in the
          team building section, Marcus is guaranteed to be a great investment.
          She has a high focus on raids (Mane’s Bulletin), which truly shows
          through her passive, “Annotation”.
        </p>
        <SectionHeader title="Lore" />
        <p>
          Marcus has a unique talent for “reading”. From books to places to
          objects to people… everything can be captured. This constant excess
          and overflow of information, however, always brought her trouble. It
          turned Marcus into a person who is always overthinking, and has a
          difficult time with decision-making and social interactions.
        </p>
        <p>
          This all changed after the Flannan Islands incident. Marcus recorded
          in detail everything that happened there, which earned her a ticket to
          the Foundation and a place under the protective wing of a mentor:
          Madam Hofmann. With the help of her new mentor, Marcus no longer was
          tormented by decision-making or complex interpersonal relationships.
          She could focus on what she’s actually good at — reading. The future
          seemed bright.
        </p>
        <p>
          Yet, the future is not as predictable as a book that we can check the
          pages ahead. When the curtain fell in Vienna, Marcus saw herself under
          the difficult position of having to read this complex, terrifying and
          beautiful world alone. But she will keep going.
        </p>
        <SectionHeader title="Review" />
        <p>
          Marcus is a 6-star character from the Plant Afflatus, a Mental Damage
          dealer. Her mechanic revolves around the new [Annotate] mechanic and
          its gain of [Eureka]. To give a simple explanation of [Annotate]
          first: it’s an ability you’ll need to inflict on your enemy, in this
          case through your passive. At the start of the round, if no enemies
          have the [Annotate] status, it’ll be inflicted on an enemy,
          prioritizing a boss. Every time they take an action, you, the caster,
          will gain one [Eureka], up to 3 times. It’s not the only way you gain
          [Eureka]; you also get it from your Ultimate. If you have more than 2
          [Eureka], you will spend all of it to randomly enhance your own
          incantations. It’s also worth noting that the enemy with [Annotate]
          will take more damage, as almost all of your skills and your Ultimate
          deal extra damage to enemies with [Annotate].
        </p>
        <p>
          Her general damage is already really high, and with the consistent
          upgrades to her own incantations and boss-like killer gameplay thanks
          to [Annotate], she’s one of the best characters available for raids,
          especially of the Plant afflatus. She also possesses great synergy
          with Plant, Spirit, and Intellect arcanists thanks to her third
          passive, giving her [Perusal] if you have 3 or more of the teammates
          with those Afflatuses. It gives you 1 Moxie to yourself when you cast
          a rank 2 or 3 incantation, which you will be doing a lot. To round out
          the review, we'll take a quick overview of all her skills in specific.
        </p>
        <p>
          Her ultimate, “Next Chapter”, is a mass attack ultimate that deals a
          lot of damage, extra to enemies with [Annotate] and it even grants
          yourself +2 Eureka. Another thing it does that I haven't mentioned is
          you gain a stack of [Preparation - Marcus], which will give you an
          extra card that can be used or mixed similar to Spathodea. Reading
          Class is a basic single target damage skill. From the Lantern is a
          mass attack that does additional damage to enemies with [Annotate]
          status and inflicts weakness. Marcus is a high-damage dealer who
          prioritizes inflicting [Annotate] on one enemy to deal extra damage,
          typically the boss, to also enhance her damage to the next level
          through self-buffing herself via incantation rank ups.
        </p>
        <h6>Pros & Cons</h6>
        <Row className="pros-cons">
          <Col sm="12" md="6">
            <div className="box pros">
              <h5>Pros</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    High burst potential, optimized for bosses thanks to her
                    [Annotate] mechanic.
                  </li>
                  <li>
                    Great synergy with most Plant, Spirit, and Intellect
                    characters.
                  </li>
                  <li>
                    Self-contained kit, making her a versatile pick and easy to
                    slot in teams.
                    <li>
                      Has some support capability in the form of consistent
                      application of [Weakness] on enemies.
                    </li>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col sm="12" md="6">
            <div className="box cons">
              <h5>Cons</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    Team building can be tricky on 3-People content, since she
                    needs specific Afflatuses on the team to trigger her
                    passive.
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <SectionHeader title="Portrays" />
        <p>Marcus works fine at P0.</p>
        <p>
          Her best Portraits are P2 and P4, as they both allow her to spam rank
          3 incantations more easily.
        </p>
        <p>
          For F2P and low spenders, P1 is also a significant boost if you can
          get lucky or afford it, since the initial extra Eureka gain allows an
          early snowball for Marcus.
        </p>
        <SectionHeader title="Great Synergies" />
        <p>
          As already mentioned, Marcus needs Plant, Intellect and Spirit allies
          in order to trigger her Insight 3 and make use of her [Perusal]
          passive. This passive is essential since it’s what allows Marcus to
          easily cycle her ult and consequently all her other mechanics. As of
          1.7, the only dedicated Plant healers are Sotheby and La Source. There
          aren’t other dedicated defensive options on the other Afflatuses
          either, making team building for 3-People content in this patch quite
          tricky.
        </p>
        <p>
          6 and Druvis are capable of covering the survival aspect through their
          buffs and passives. Otherwise, you can rely on Sotheby or even La
          Source for 3-People content. On 4-People content, this slot can be
          filled like usual by dedicated healers such as Tooth Fairy, Yenisei,
          and others.
        </p>
        <p>
          Starting on patch 1.8, Vila will become a staple of Mental teams, and
          greatly boost Marcus. Patch 1.9 brings Kakania, another great addition
          that will cover any further needs in the defensive area while also
          bringing Marcus’ damage (and anyone else’s) to the next level.
          Finally, at patch 2.0, Mercuria can be a luxury pick if you like her.
        </p>
        <p>Notable synergies include:</p>
        <div className="employees-container-card-mode-rev">
          <Card className="avatar-card">
            <Reverse1999Character
              slug="6"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="druvis-iii"
              showLabel
              mode="icon"
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="isolde"
              showLabel
              mode="icon"
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="an-an-lee"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <p>Future notable synergies:</p>
        <div className="employees-container-card-mode-rev">
          <Card className="avatar-card">
            <Reverse1999Character
              slug="vila"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="kakania"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="mercuria"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <SectionHeader title="Psychubes" />
        <p>Here are the best Psychubes you can use on Isolde:</p>
        <div className={`employees-container psychube`}>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="another-story"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="blasphemer-of-night"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="his-bounden-duty"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <p>
          “Another Story” is Marcus’s tailored Psychube, and a good option to
          invest in overall, as it benefits many other DMG dealers and has very
          high scaling on its Amplifications. If you plan to use Marcus as your
          Plant DPS for raids, this Psychube is a no-brainer.
        </p>
        <p>
          Other good options if you don’t have “Another Story” readily
          available, are “Blasphemer of Night” for a similar boost in
          Incantation Might (the source of most of Marcus’ DMG), or the ever
          reliable “His Bounden Duty”. This one can be particularly helpful if
          you’re struggling to fit a healer in your team comp.
        </p>
        <SectionHeader title="Resonance" />
        <h6>Level 7</h6>
        <StaticImage
          src="../../../images/reverse/generic/marcus_r_1.webp"
          alt="Guides"
        />
        <h6>Level 9</h6>
        <StaticImage
          src="../../../images/reverse/generic/marcus_r_2.webp"
          alt="Guides"
        />
        <h6>Level 10</h6>
        <StaticImage
          src="../../../images/reverse/generic/marcus_r_3.webp"
          alt="Guides"
        />
        <br />
        <StaticImage
          src="../../../images/reverse/generic/marcus_r_4.webp"
          alt="Guides"
        />
        <SectionHeader title="Teams" />
        <p>
          Marcus teams work on a dichotomy of being limited and open at the same
          time. The only actual rule is that you need, aside from Marcus
          herself, two other Plant/Intellect/Spirit characters on the team.
          Under that scope, almost anything can work. Not to mention, while the
          4th slot choices suggested here are focused on possible alternatives
          for the 3-P teams, the 4th slot is actually fair game, and you can
          bring whoever you want regardless of Afflatus.
        </p>
        <h5>Standard Teams</h5>
        <div className="team-box">
          <h5 className="star">
            Lectures on Literature <span className="tag">[GLB]</span>
          </h5>
          <div className="team">
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="marcus"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Damage Dealer</h6>
            </div>
            <div className="role mid">
              <div className="characters small">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="6"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Support</h6>
            </div>
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="druvis-iii"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="sustain">Sustain</h6>
            </div>
          </div>
          <div className="info">
            <h6>TEAM EXPLANATION</h6>
            <ul>
              <li>
                4th slot options: <strong>Sotheby, Isolde, An-An Lee</strong>
              </li>
              <li>
                For most content 3-P content, 6 or Druvis are enough to keep the
                team alive while aiding with buffs and debuffs. (Reminder that
                this only works if Druvis is i3).
              </li>
              <li>
                If you’re feeling daunting, Druvis can be replaced by Isolde for
                a swift sweep of the stage. On the other hand, if you’re
                struggling with sustain, Sotheby or even La Source may fill in
                the Sustain slot.
              </li>
              <li>
                For the fourth slot, any of the already mentioned characters
                work, but since Marcus is relatively squishy, 4-People content
                allow you freedom to bring a more dedicated healer or shielder
                of your choice.
              </li>
            </ul>
          </div>
        </div>
        <div className="team-box">
          <h5 className="star">
            The Dialogic Imagination<span className="tag">[CN]</span>
          </h5>
          <div className="team">
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="marcus"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Damage Dealer</h6>
            </div>
            <div className="role mid">
              <div className="characters small">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="kakania"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Support</h6>
            </div>
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="vila"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="sustain">Sustain</h6>
            </div>
          </div>
          <div className="info">
            <h6>TEAM EXPLANATION</h6>
            <ul>
              <li>
                4th slot options:{' '}
                <strong>Isolde, An-An Lee, 6, Mercuria</strong>
              </li>
              <li>
                The future “ideal” Marcus team, if you wish to prepare ahead.
              </li>
            </ul>
          </div>
        </div>
        <div className="team-box">
          <h5 className="star">
            Natural Supernaturalism <span className="tag">[GLB]</span>
          </h5>
          <div className="team">
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="marcus"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Damage Dealer</h6>
            </div>
            <div className="role mid">
              <div className="characters small">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="winter"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Support</h6>
            </div>
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="la-source"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="sustain">Sustain</h6>
            </div>
          </div>
          <div className="info">
            <h6>TEAM EXPLANATION</h6>
            <ul>
              <li>
                4th slot options: <strong>Avgust, Bkornblume, Diggers</strong>
              </li>
              <li>
                The budget team. Despite this team being extremely squishy,
                Zima’s CC and buffs can aid on its survival, as well as maximize
                La Source’s healing.
              </li>
              <li>
                You can mix and match with the 4th slot options depending on who
                you have available. Needless to say, these are just the cheapest
                and more easily available choices possible. You can replace any
                of these characters for higher rarity options already listed on
                other teams if you have them and wish to do that.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default RevShouldIsolde;

export const Head: React.FC = () => (
  <Seo
    title="Should you pull Marcus? | Reverse: 1999 | Prydwen Institute"
    description="Not sure if pulling Marcus is the right move? No worries! Check our review and guide for her!"
    game="reverse"
  />
);
